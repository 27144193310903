import { Row, Col, Form, Container, Button } from "react-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";

import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import { DefaultizedPieValueType } from '@mui/x-charts/models';
import { LineChart } from '@mui/x-charts/LineChart';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { itIT } from "@mui/x-date-pickers/locales";
import 'dayjs/locale/it.js';
import dayjs from 'dayjs'

// mui tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

// import dati x grafici
import { dataset } from './../../../constants/datiAnalisiPieAnnuale.ts';
import { platforms, platformsResource1, platformsResource2, platformsResource3, 
         platformsResource4, platformsResource5, platformsResource6 } from './../../../constants/datiAnalisiGrafici.ts';
import { desktopOS, valueFormatter } from './../../../constants/datiAnalisiGrafici.ts';

const palette = ['lightcoral', 'slateblue', '#02B2AF', 'yellow'];
const paletteResource = ['lightcoral', 'slateblue', '#02B2AF', 'yellow'];

const AnalyticsPageComponent = ({
  fetchOrdersForFirstDate,
  fetchOrdersForSecondDate,
  socketIOClient
}) => {
  const [firstDateToCompare, setFirstDateToCompare] = useState(
    new Date().toISOString().substring(0, 10)
  );
  var previousDay = new Date();
  previousDay.setDate(previousDay.getDate() - 1);
  const [secondDateToCompare, setSecondDateToCompare] = useState(
    new Date(previousDay).toISOString().substring(0, 10)
  );

  const [dataForFirstSet, setDataForFirstSet] = useState([]);
  const [dataForSecondSet, setDataForSecondSet] = useState([]);

  const [dataAppuntamentiMillis, setDataAppuntamentiMillis] = useState(dayjs(new Date()));                                     // oggetto
  
  function popupConferma (eventKey,giornoAgendaMillis) {
  }

  useEffect(() => {
      const socket = socketIOClient();
      let today = new Date().toDateString();
      const handler = (newOrder) => {
          var orderDate = new Date(newOrder.createdAt).toLocaleString("en-US", { hour: "numeric", hour12: true, timeZone: "UTC" });
          if (new Date(newOrder.createdAt).toDateString() === today) {
              if (today === new Date(firstDateToCompare).toDateString()) {
                  setDataForFirstSet((prev) => {
                      if (prev.length === 0) {
                         return [{ name: orderDate, [firstDateToCompare]: newOrder.orderTotal.cartSubtotal }]; 
                      }
                      const length = prev.length;
                      if (prev[length - 1].name === orderDate) {
                          prev[length - 1][firstDateToCompare] += newOrder.orderTotal.cartSubtotal;
                          return [...prev];
                      } else {
                        var lastElem = { name: orderDate, [firstDateToCompare]: prev[length - 1][firstDateToCompare] + newOrder.orderTotal.cartSubtotal }; 
                        return [...prev, lastElem];
                      }
                  })
              }
              else if (today === new Date(secondDateToCompare).toDateString()) {
                  setDataForSecondSet((prev) => {
                      if (prev.length === 0) {
                          return [{ name: orderDate, [secondDateToCompare]: newOrder.orderTotal.cartSubtotal }];
                      }
                      const length = prev.length;
                      if (prev[length - 1].name === orderDate) {
                         prev[length - 1][secondDateToCompare] += newOrder.orderTotal.cartSubtotal; 
                         return [...prev];
                      } else {
                          var lastElem = { name: orderDate, [secondDateToCompare]: prev[length - 1][secondDateToCompare] + newOrder.orderTotal.cartSubtotal };
                          return [...prev, lastElem];
                      }
                  })
              }
          }
      }
      socket.on("newOrder", handler);
      return () => socket.off("newOrder", handler);
  }, [setDataForFirstSet, setDataForSecondSet, firstDateToCompare, secondDateToCompare])

  useEffect(() => {
    const abctrl = new AbortController();
    fetchOrdersForFirstDate(abctrl, firstDateToCompare)
      .then((data) => {
        let orderSum = 0;
        const orders = data.map((order) => {
          orderSum += order.orderTotal.cartSubtotal;
          var date = new Date(order.createdAt).toLocaleString("en-US", {
            hour: "numeric",
            hour12: true,
            timeZone: "UTC",
          });
          return { name: date, [firstDateToCompare]: orderSum };
        });
        setDataForFirstSet(orders);
      })
      .catch((er) =>
        console.log(
          er.response.data.message ? er.response.data.message : er.response.data
        )
      );

    fetchOrdersForSecondDate(abctrl, secondDateToCompare)
      .then((data) => {
        let orderSum = 0;
        const orders = data.map((order) => {
          orderSum += order.orderTotal.cartSubtotal;
          var date = new Date(order.createdAt).toLocaleString("en-US", {
            hour: "numeric",
            hour12: true,
            timeZone: "UTC",
          });
          return { name: date, [secondDateToCompare]: orderSum };
        });
        setDataForSecondSet(orders);
      })
      .catch((er) =>
        console.log(
          er.response.data.message ? er.response.data.message : er.response.data
        )
      );
    return () => abctrl.abort();
  }, [firstDateToCompare, secondDateToCompare]);

  const firstDateHandler = (e) => {
    setFirstDateToCompare(e.target.value);
  };

  const secondDateHandler = (e) => {
    setSecondDateToCompare(e.target.value);
  };

  const pieParams = {
    height: 250,
    margin: { right: 5 },
    slotProps: { legend: { hidden: true } },
  };

  const totalResource1 = platformsResource1.map((item) => item.value).reduce((a, b) => a + b, 0);
  const totalResource2 = platformsResource2.map((item) => item.value).reduce((a, b) => a + b, 0);
  const totalResource3 = platformsResource3.map((item) => item.value).reduce((a, b) => a + b, 0);
  const totalResource4 = platformsResource4.map((item) => item.value).reduce((a, b) => a + b, 0);
  const totalResource5 = platformsResource5.map((item) => item.value).reduce((a, b) => a + b, 0);
  const totalResource6 = platformsResource6.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabelResource1 = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalResource1;
    return `${(percent * 100).toFixed(0)}%`;
  };
  const getArcLabelResource2 = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalResource2;
    return `${(percent * 100).toFixed(0)}%`;
  };
  const getArcLabelResource3 = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalResource3;
    return `${(percent * 100).toFixed(0)}%`;
  };
  const getArcLabelResource4 = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalResource4;
    return `${(percent * 100).toFixed(0)}%`;
  };
  const getArcLabelResource5 = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalResource5;
    return `${(percent * 100).toFixed(0)}%`;
  };
  const getArcLabelResource6 = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalResource6;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const totalCategories = desktopOS.map((item) => item.value).reduce((a, b) => a + b, 0);
  const getArcLabelCategories = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalCategories;
    return `${(percent * 100).toFixed(0)}%`;
  };


  const [tabAttivo, setTabAttivo] = React.useState('one');

  const handleChange1 = (event, newValue) => {
    setTabAttivo(newValue);
  };


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTabAttivo('appuntamenti');
  }, [setTabAttivo]);


  return (

      <Row className="m-5">

        <Col md={2}>
          <AdminLinksComponent />
        </Col>

        <Col md={10}>

          <h1>
            Analisi 
          </h1>

          <Box sx={{ bgcolor: 'background.paper', width: 800 }}>

            <AppBar position="static" >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Appuntamenti" {...a11yProps(0)} />
                <Tab label="Risorse" {...a11yProps(1)} />
                <Tab label="Categorie" {...a11yProps(2)} />
                <Tab label="Storico" {...a11yProps(3)} />
              </Tabs>
            </AppBar>

            {/* ------------------------ tab 1 ---------------------- */}

            <TabPanel value={value} index={0} dir={theme.direction}>

              <Row >
                <Col md={4}> 
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                    >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker  
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={4}>     
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={4}>     
                  <Button variant="secondary"
                          onClick={(e) => {popupConferma(e, dataAppuntamentiMillis) }}
                    >
                      Calcola
                  </Button>
                </Col>

              </Row >

              <PieChart
                series={[
                  {
                    data: desktopOS,
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    valueFormatter,
                    arcLabel: getArcLabelCategories,
                  },
                ]}
                height={400}
              />

              <hr />

              <LineChart
                dataset={dataset}
                xAxis={[
                  {
                    id: 'Years',
                    dataKey: 'date',
                    scaleType: 'time',
                    valueFormatter: (date) => date.getFullYear().toString(),
                  },
                ]}
                series={[
                  {
                    id: 'TaglioDonna',
                    label: 'Taglio donna',
                    dataKey: 'td',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                  {
                    id: 'TaglioUomo',
                    label: 'Taglio uomo',
                    dataKey: 'tu',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                  {
                    id: 'Unghie',
                    label: 'Unghie',
                    dataKey: 'un',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                  {
                    id: 'Viso',
                    label: 'Trattamenti viso',
                    dataKey: 'tv',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                ]}
                width={800}
                height={400}
                margin={{ left: 70 }}
              />


            </TabPanel>
            
            {/* ------------------------ tab 2 ---------------------- */}

            <TabPanel value={value} index={1} dir={theme.direction}>
              <Row >
                <Col md={4}>
                <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                    >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={4}>     
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={2}>     
                  <Button variant="secondary"
                          onClick={(e) => {popupConferma(e, dataAppuntamentiMillis) }}
                    >
                      Calcola
                  </Button>
                </Col>
                
              </Row >

              <BarChart
                  xAxis={[{ scaleType: 'band', data: ['Taglio donna', 'Taglio uomo', 'Unghie', 'Trattamenti Viso'] }]}
                  series={[{ data: [14, 9, 15, 20, 10, 18] }, { data: [12, 16, 23, 18, 19, 9] }, { data: [8, 15, 16, 14, 14, 7] }, { data: [16, 20, 18, 9, 16, 18] }]}
                  width={800}
                  height={400}
              />

              <hr />

              <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Giorgia</Typography>
                  <PieChart
                    colors={paletteResource}
                    series={[
                      {
                        data: platformsResource1,
                        arcLabel: getArcLabelResource1,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Vanessa</Typography>
                  <PieChart
                    colors={paletteResource}
                    series={[
                      {
                        data: platformsResource2,
                        arcLabel: getArcLabelResource2,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Giulia</Typography>
                  <PieChart
                    colors={paletteResource}
                    series={[
                      {
                        data: platformsResource3,
                        arcLabel: getArcLabelResource3,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
                </Stack>

                <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Luisa</Typography>
                  <PieChart
                    colors={paletteResource}
                    series={[
                      {
                        data: platformsResource4,
                        arcLabel: getArcLabelResource4,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Antonella</Typography>
                  <PieChart
                    colors={paletteResource}
                    series={[
                      {
                        data: platformsResource5,
                        arcLabel: getArcLabelResource5,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Michela</Typography>
                  <PieChart
                    colors={paletteResource}
                    series={[
                      {
                        data: platformsResource6,
                        arcLabel: getArcLabelResource6,
                      },
                    ]}
                    {...pieParams}
                  />
                </Box>
              </Stack>

            </TabPanel>
            
            {/* ------------------------ tab 3 ---------------------- */}

            <TabPanel value={value} index={2} dir={theme.direction}>
              <Row >
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={4}>     
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={2}>     
                  <Button variant="secondary"
                          onClick={(e) => {popupConferma(e, dataAppuntamentiMillis) }}
                    >
                      Calcola
                  </Button>
                </Col>
                
              </Row >

              <BarChart
                  xAxis={[{ scaleType: 'band', data: ['Taglio donna', 'Taglio uomo', 'Unghie', 'Trattamenti Viso'] }]}
                  series={[{ data: [14, 9, 15, 20, 10, 18] }, { data: [12, 16, 23, 18, 19, 9] }, { data: [8, 15, 16, 14, 14, 7] }, { data: [16, 20, 18, 9, 16, 18] }]}
                  width={800}
                  height={250}
              />

            </TabPanel>
          
            {/* ------------------------ tab 4 ---------------------- */}

            <TabPanel value={value} index={3} dir={theme.direction}>
              <Row >
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={4}>     
                  <Form.Group className="mb-3" controlId="validationCustom01" 
                    style={{
                      width: "180px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                      <DatePicker 
                        defaultValue={dataAppuntamentiMillis}
                        value={dataAppuntamentiMillis}
                        onChange={setDataAppuntamentiMillis}
                        inputFormat="dd-MM-yyyy"
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Form.Group>
                </Col>

                <Col md={2}>     
                  <Button variant="secondary"
                          onClick={(e) => {popupConferma(e, dataAppuntamentiMillis) }}
                    >
                      Calcola
                  </Button>
                </Col>
                
              </Row >

              <LineChart
                dataset={dataset}
                xAxis={[
                  {
                    id: 'Years',
                    dataKey: 'date',
                    scaleType: 'time',
                    valueFormatter: (date) => date.getFullYear().toString(),
                  },
                ]}
                series={[
                  {
                    id: 'TaglioDonna',
                    label: 'Taglio donna',
                    dataKey: 'td',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                  {
                    id: 'TaglioUomo',
                    label: 'Taglio uomo',
                    dataKey: 'tu',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                  {
                    id: 'Unghie',
                    label: 'Unghie',
                    dataKey: 'un',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                  {
                    id: 'Viso',
                    label: 'Trattamenti viso',
                    dataKey: 'tv',
                    stack: 'total',
                    area: true,
                    showMark: false,
                  },
                ]}
                width={800}
                height={500}
                margin={{ left: 70 }}
              />

            </TabPanel>

          </Box>

      </Col>

      </Row>

  );
};

export default AnalyticsPageComponent;
