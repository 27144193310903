import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import equals from 'validator/lib/equals';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Select from '@mui/material/Select';

const EditUserPageComponent = ({ updateUserApiRequest, fetchUser }) => {

  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState([]);
  const [isAdminState, setIsAdminState] = useState(false);
  const [updateUserResponseState, setUpdateUserResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [lastName, setLastName] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [phoneNumber, setPhoneNumber] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [country, setCountry] = useState("");                      
  const [zipCode, setZipCode] = useState("");                      
  const [city, setCity] = useState("");                      
  const [state, setState] = useState("");                      
  const [password, setPassword] = useState("");                      
  const [repassword, setRepassword] = useState("");                      
  const [role, setRole] = useState("");                      
  const [isAdmin, setIsAdmin] = useState(false);                        
  
  const [fieldNameError, setFieldNameError] = useState(false);
  const [fieldLastnameError, setFieldLastnameError] = useState(false);
  const [fieldEmailError, setEmailFieldError] = useState(false);
  const [fieldPasswordError, setFieldPasswordError] = useState(false);
  const [fieldRePasswordError, setFieldRePasswordError] = useState(false);
  
  useEffect(() => {
    fetchUser(id)
    .then(data => {
      console.log(`data =`, data);  
      setName(data?.name);
      setLastName(data?.lastName);
      setEmail(data?.email);
      setPhoneNumber(data?.phoneNumber);
      setAddress(data?.address);
      setCountry(data?.country);
      setZipCode(data?.zipCode);
      setCity(data?.city);
      setState(data?.state);
      setPassword(data?.password);
      setRepassword(data?.password);
      setRole(data?.role);
      setIsAdmin(data?.isAdmin);
      console.log(`name  =`, name);  
      console.log(`lastName  =`, lastName);  
      console.log(`email  =`, email);  
      console.log(`phoneNumber  =`, phoneNumber);  
      console.log(`address  =`, address);  
      console.log(`country  =`, country);  
      console.log(`zipCode  =`, zipCode);  
      console.log(`city  =`, city);  
      console.log(`state  =`, state);  
      console.log(`password  =`, password);  
      console.log(`role  =`, role);  
      console.log(`isAdmin  =`, isAdmin);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
  },[id])
  
  function salvaUserModificato (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();
  
    console.log(`name dig =`, name);  
    console.log(`lastName dig =`, lastName);  
    console.log(`email dig =`, email);  
    console.log(`phoneNumber dig =`, phoneNumber);  
    console.log(`address dig =`, address);  
    console.log(`country dig =`, country);  
    console.log(`zipCode dig =`, zipCode);  
    console.log(`city dig =`, city);  
    console.log(`state dig =`, state);  
    console.log(`password dig =`, password);  
    console.log(`role dig =`, role);  
    console.log(`code isAdmin =`, isAdmin);  
  
    //setIsAdmin(false);

    if (
      name &&
      lastName &&
      email &&
      password &&
      repassword &&
      (password===repassword) 
      ) {

        updateUserApiRequest(id, name, lastName, email, role, isAdmin, address, city, country, phoneNumber, state, zipCode, password)
        .then(data => {
            if (data.success === "user updated") {
                navigate("/admin/users");
            }
          })
        .catch(er => {
            setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
        })
      
        window.location.assign('/admin/users')
      }

  }
  
  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();
  
    window.location.assign('/admin/users')
  }
   
  const handleSelectRole = (event) => {
    setRole(event.target.value);
  };

  return (
    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Modifica utente</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
                style={{
                  width: "200px",
                }}
                error={fieldNameError}
                onChange={(event) => {
                  setName(event.target.value);
                  if (isLength(event.target.value,{ min: 3, max:15})) {
                    setFieldNameError(false);
                  } else {
                    setFieldNameError(true);
                  }   
                }}
              />

              <TextField id="id-lastName" label="Cognome" variant="outlined" value={lastName} defaultValue={lastName} 
                style={{
                  width: "200px",
                }}
                error={fieldLastnameError}
                onChange={(event) => {
                  setLastName(event.target.value);
                  if (isLength(event.target.value,{ min: 3, max:15})) {
                    setFieldLastnameError(false);
                  } else {
                    setFieldLastnameError(true);
                  }   
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" value={email} defaultValue={email} 
                style={{
                  width: "400px",
                }}
                error={fieldEmailError}
                onChange={(event) => {
                  setEmail(event.target.value);
                  if (isEmail(event.target.value)) {
                    setEmailFieldError(false);
                  } else {
                    setEmailFieldError(true);
                  }   
                }}
              />

              <TextField id="id-phoneNumber" label="Telefono" variant="outlined" value={phoneNumber} defaultValue={phoneNumber} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
              />

              <TextField id="id-address" label="Indirizzo" variant="outlined" value={address} defaultValue={address} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />
              <TextField id="id-city" label="Città" variant="outlined" value={city} defaultValue={city} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
              <TextField id="id-country" label="Provincia" variant="outlined" value={country} defaultValue={country} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCountry(event.target.value);
                }}
              />
              <TextField id="id-zipCode" label="Cap" variant="outlined" value={zipCode} defaultValue={zipCode} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setZipCode(event.target.value);
                }}
              />

              <FormControl                          
                style={{
                          width: "150px",
                      }}
              >
                <InputLabel id="demo-simple-select-label">
                  Ruolo
                </InputLabel >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Ruolo"
                  onChange={handleSelectRole}
                >
                  <MenuItem value="Utente">Utente</MenuItem>
                  <MenuItem value="Cliente">Cliente</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Owner">Owner</MenuItem>
                </Select>
              </FormControl>

              <TextField id="id-password" label="Password" variant="outlined" value={password} defaultValue={password} type="password"
                style={{
                  width: "200px",
                }}
                error={fieldPasswordError}
                onChange={(event) => {
                  setPassword(event.target.value);
                  if (isStrongPassword(event.target.value)) {
                    setFieldPasswordError(false);
                  } else {
                    setFieldPasswordError(true);
                  }   
                }}
              />

            <TextField id="id-repassword" label="Ripeti password" variant="outlined" value={repassword} defaultValue={repassword} required type="password"
                style={{
                  width: "200px",
                }}
                error={fieldRePasswordError}
                onChange={(event) => {
                  setRepassword(event.target.value);
                  if (isStrongPassword(event.target.value)) {
                    setFieldRePasswordError(false);
                  } else {
                    setFieldRePasswordError(true);
                  }   
                }}
              />
      
        {/* 
              <FormControlLabel control={
                <Checkbox id="id-isAdmin" label="Admin" variant="outlined" value={isAdmin} defaultValue={isAdmin} 
                  style={{
                    width: "30px",
                  }}
                  onChange={(event) => {
                    setIsAdmin(isAdmin);
                  }}
                  sx={{
                    color: pink[800],
                    '&.Mui-checked': {
                      color: pink[600],
                    },
                  }}
                />
              } label="Admin" />
        */}

           
            </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
          <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaUserModificato(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             

          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditUserPageComponent;
