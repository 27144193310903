import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import * as React from 'react';
import axios from "axios";

const GiftCardPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const config = require("../../constants/constants.js")

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;

    const email = form.email.value;
    const password = form.password.value;
    const doNotLogout = form.doNotLogout.checked;

    if (event.currentTarget.checkValidity() === true && email && password) {
        setLoginUserResponseState({ loading: true });
      loginUserApiRequest(email, password, doNotLogout)
        .then((res) => {
            setLoginUserResponseState({ success: res.success, loading: false, error: "" });

            if (res.userLoggedIn) {
                reduxDispatch(setReduxUserState(res.userLoggedIn));
            }

            if (res.success === "user logged in" && !res.userLoggedIn.isAdmin) window.location.assign('/user') 
            else window.location.assign('/admin/categories')

        })
        .catch((er) =>
          setLoginUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data })
        );
    }

    setValidated(true);
  };
  
  const [page, setPage] = useState({});  
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [customizedName, setCustomizedName] = useState("");     
  const [customizedStreet, setCustomizedStreet] = useState("");     
  const [customizedZip, setCustomizedZip] = useState("");     
  const [customizedCity, setCustomizedCity] = useState("");     
  const [customizedEmail, setCustomizedEmail] = useState("");     
  const [tabAttivo, setTabAttivo] = React.useState('home');
  const [pathImage1, setPathImage1] = useState("");     
  const [pathImage2, setPathImage2] = useState("");     
  const [pathImage3, setPathImage3] = useState("");     
  const [titleImage1, setTitleImage1] = useState("");     
  const [subTitleImage1, setSubTitleImage1] = useState("");     
  const [titleImage2, setTitleImage2] = useState("");     
  const [subTitleImage2, setSubTitleImage2] = useState("");     
  const [titleImage3, setTitleImage3] = useState("");     
  const [subTitleImage3, setSubTitleImage3] = useState("");     

  const getPageByMenuValueApiRequest = async (menuValue) => {
    console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
    const url = `/api/pages/menuValue/`+menuValue;
    const { data } = await axios.get(url);
    console.log(`data =`, data);  
    return data
  }

  useEffect(() => {
    getPageByMenuValueApiRequest('CONVEGNI EVENTI').then((data) => { 
      console.log(`data 1 =`, data);  
      setPage(data);
      setTitle(data?.title.text);
      setSubTitle(data?.subTitle.text);
      setDescription(data?.description.text);
      setTabAttivo('home');
      setId(data?._id);
      setPathImage1(data?.images[0].path);
      setPathImage2(data?.images[1].path);
      setPathImage3(data?.images[2].path);
      setCustomizedName(page?.customizedContent.name.text);
      setCustomizedStreet(page?.customizedContent.street.text);
      setCustomizedZip(page?.customizedContent.zip.text);
      setCustomizedCity(page?.customizedContent.city.text);
      setCustomizedEmail(page?.customizedContent.email.text);
      setTitleImage1(data?.imagesTitle[0].text);
      setSubTitleImage1(data?.imagesSubTitle[0].text);
      setTitleImage2(data?.imagesTitle[1].text);
      setSubTitleImage2(data?.imagesSubTitle[1].text);
      setTitleImage3(data?.imagesTitle[2].text);
      setSubTitleImage3(data?.imagesSubTitle[2].text);
      console.log(`id =`, id);  
      console.log(`page?.title.text =`, page?.title.text);  
      console.log(`page?.description.text =`, page?.description.text);  
      console.log(`page?.customizedContent =`, page?.customizedContent);  
      console.log(`page?.customizedContent.name.text =`, page?.customizedContent.zip.text);  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [tabAttivo,id])

  return (

    <Container className="px-0">
    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >
      <Col
        xs={{ order: 2 }}
        md={{ size: 4, order: 1 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage1}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage1}</h3> 
              <p>{subTitleImage1}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage2}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage2}</h3> 
              <p>{subTitleImage2}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage3}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage3}</h3> 
              <p>{subTitleImage3}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

      <Col
        xs={{ order: 1 }}
        md={{ size: 7, offset: 1 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

        <h1>{title}</h1>

        <Form.Group className="mb-3" controlId="validationCustom01" >
          <Form.Label><em>&nbsp;{subTitle}</em></Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            {description} 
          </Form.Label>
        </Form.Group>

      </Col>
    </Row>
    
    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>

  );
};

export default GiftCardPageComponent;
