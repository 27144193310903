import {
  Row,
  Col,
  Container,
  Image,
  ListGroup,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import AddedToCartMessageComponent from "../../components/AddedToCartMessageComponent";
import ImageZoom from "js-image-zoom";
import { useEffect, useState, useRef } from "react";
import MetaComponent from "../../components/MetaComponent";
import { useParams } from "react-router-dom";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { LinkContainer } from "react-router-bootstrap";

const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')

const ProductDetailsPageComponent = ({
  addToCartReduxAction,
  reduxDispatch,
  getProductDetails,
  userInfo,
  writeReviewApiRequest
}) => {
  const { id } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [showCartMessage, setShowCartMessage] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productReviewed, setProductReviewed] = useState(false);

  const messagesEndRef = useRef(null);

  const addToCartHandler = () => {
    reduxDispatch(addToCartReduxAction(id, quantity));
    setShowCartMessage(true);
  };

  useEffect(() => {
    if (productReviewed) {
        setTimeout(() => {
             messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }, 200)
    }  
  }, [productReviewed])

  useEffect(() => {
    if (product.images) {
      var options = {
        // width: 400,
        // zoomWidth: 500,
        // fillContainer: true,
        // zoomPosition: "bottom",
        scale: 2,
        offset: { vertical: 0, horizontal: 0 },
      };

      product.images.map(
        (image, id) =>
          new ImageZoom(document.getElementById(`imageId${id + 1}`), options)
      );
    }
  });

  useEffect(() => {
    getProductDetails(id)
      .then((data) => {
        setProduct(data);
        setLoading(false);
      })
      .catch((er) =>
        setError(
          er.response.data.message ? er.response.data.message : er.response.data
        )
      );
  }, [id, productReviewed]);

  const sendReviewHandler = (e) => {
     e.preventDefault();
     const form = e.currentTarget.elements;
     const formInputs = {
         comment: form.comment.value,
         rating: form.rating.value,
     }
     if (e.currentTarget.checkValidity() === true) {
         writeReviewApiRequest(product._id, formInputs)
         .then(data => {
             if (data === "review created") {
                 setProductReviewed("Hai pubblicato la tua recensione");
             }
         })
         .catch((er) => setProductReviewed(er.response.data.message ? er.response.data.message : er.response.data));
     }
  }

  return (
      <>
      <MetaComponent title={product.name} description={product.description}/>
      <Container>
      <AddedToCartMessageComponent
        showCartMessage={showCartMessage}
        setShowCartMessage={setShowCartMessage}
      />
      <Row className="mt-5">
        {loading ? (
          <h2>Caricamento dettagli prodotto ...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <>

            {/* INI colonna tre immagini  */}
                       
            <Col style={{ zIndex: 1 }} md={4}>

              <div class="carousel-wrapper">
                <Carousel infiniteLoop useKeyboardArrows showArrows={true}>

                  {product.images.map((slide, i) => (
                    <div
                      key={i}
                    >
                      <img
                        src={slide.path}
                        className='d-block w-100'
                        alt={slide.path}
                      />
                    </div>
                  ))}

                </Carousel>
              </div>

            </Col>

            {/* FIN colonna tre immagini  */}            

            <Col md={6}>
              <Row>
                <Col md={6}>

                  <ListGroup variant="flush"
                    style={{
                      border: "0",
                    }}
                  >

                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      <h1>{product.name}</h1>
                    </ListGroup.Item>
                    
                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      <Rating
                        readonly
                        size={20}
                        initialValue={product.rating}
                      />{" "}
                      ({product.reviewsNumber ? product.reviewsNumber : 0})
                    </ListGroup.Item>

                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      Prezzo <span className="fw-bold">{strEuroCurrency(product.price)}</span>
                    </ListGroup.Item>
                    
                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      {product.description}
                    </ListGroup.Item>
                 
                  </ListGroup>
                </Col>
                <Col md={6}>
        {/* 
                  <ListGroup>
                    <ListGroup.Item>
                      Disponibile: {product.count > 0 ? "in stock" : "out of stock"}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Prezzo: <span className="fw-bold">€{product.price}</span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Quantità:
                      <Form.Select
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        size="sm"
                        aria-label="Default select example"
                      >
                        {[...Array(product.count).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Select>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Button onClick={addToCartHandler} variant="success">
                        Aggiungi al carrello
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
        */}

                </Col>
              </Row>
              <Row>
                <Col className="mt-5">
        {/* 
                  <h5>RECENSIONI</h5>
                  <ListGroup variant="flush">
                    {product.reviews &&
                      product.reviews.map((review, idx) => (
                        <ListGroup.Item key={idx}>
                          {review.user.name} <br />
                          <Rating readonly size={20} initialValue={review.rating} />
                          <br />
                          {review.createdAt.substring(8, 10)+'/'+review.createdAt.substring(5, 7)+'/'+review.createdAt.substring(0, 4)} <br />
                          {review.comment}
                        </ListGroup.Item>
                      ))}
                      <div ref={messagesEndRef} />
                  </ListGroup>
        */}
                </Col>
              </Row>

              <Row className="mt-5 justify-content-md-center">
                <Col md={4}>
                </Col>
                <Col md={8}>
                  <LinkContainer to={`/`}>
                    <Button variant="success">Servizi</Button>
                  </LinkContainer>
                </Col>
              </Row>

              <Row className="mt-5 justify-content-md-center">
                <Col md={6}>
                </Col>
              </Row>

        {/* 
              {!userInfo.name && <Alert variant="danger">Fai login per scrivere una recensione</Alert>}
              
              <Form onSubmit={sendReviewHandler}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Scrivi una recensione</Form.Label>
                  <Form.Control name="comment" required as="textarea" disabled={!userInfo.name} rows={3} />
                </Form.Group>
                <Form.Select name="rating" required disabled={!userInfo.name} aria-label="Default select example">
                  <option value="">Seleziona le stelle</option>
                  <option value="5">5 (molto bene)</option>
                  <option value="4">4 (bene)</option>
                  <option value="3">3 (media)</option>
                  <option value="2">2 (cattivo)</option>
                  <option value="1">1 (terribile)</option>
                </Form.Select>
                <Button disabled={!userInfo.name} type="submit" className="mb-3 mt-3" variant="primary">
                  Invia recensione
                </Button>{" "}
                {productReviewed}
              </Form>
        */}

            </Col>
          </>
        )}
      </Row>
    </Container>
      </>
    
  );
};

export default ProductDetailsPageComponent;
