import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { logout } from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import { lightBlue } from '@mui/material/colors'

const activeState = ({ isActive, isPending }) => {
  return {
    color: isPending ? "rgb(253 230 138)" : "",
    backgroundColor: isActive ? "rgb(69 26 3)" : "",
    fontWeight: isActive ? "bold" : ""
  };
};

const AdminLinksComponent = () => {
    const dispatch = useDispatch()
  return (

    <Navbar   
      style=
        {{
          fontWeight: 500,                           
          color: "#084473",
          background: "#f5f5e2",
          marginTop: 60,   
          width: 100,         
        }}>

      <Nav className="flex-column" >

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Gestisci le categorie"
          to="/admin/categories">
          <Nav.Link>Categorie</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Gestisci i prodotti"
          to="/admin/products">
          <Nav.Link>Servizi</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Gestisci le risorse"
          to="/admin/resources">
          <Nav.Link>Risorse</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Gestisci i siti/postazioni"
          to="/admin/sites">
          <Nav.Link>Siti</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Gestisci i clienti"
          to="/admin/customers">
          <Nav.Link>Clienti</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Gestisci gli utenti"
          to="/admin/users">
          <Nav.Link>Utenti</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Analisi dati"
          to="/admin/analytics">
          <Nav.Link>Analisi</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Modifica configurazione"
          to="/admin/configs">
          <Nav.Link>Configura</Nav.Link>
        </LinkContainer>

        <LinkContainer 
          data-bs-toggle="tooltip" 
          data-bs-placement="right"
          title="Vedi le chat attive"
          to="/admin/chats">
          <Nav.Link>Chat</Nav.Link>
        </LinkContainer>
  
        <Nav.Link onClick={() => dispatch(logout())}>Logout</Nav.Link>
  
      </Nav>
  
    </Navbar>
  );
};

export default AdminLinksComponent;
