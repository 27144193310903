export const dataset = [
    { date: new Date(1990, 0, 1), td: 281, tu: 261, un: 253, tv: 253 },
    { date: new Date(1991, 0, 1), td: 282, tu: 257, un: 267, tv: 253 },
    { date: new Date(1992, 0, 1), td: 286, tu: 257, un: 273, tv: 210 },
    { date: new Date(1993, 0, 1), td: 283, tu: 263, un: 272, tv: 215 },
    { date: new Date(1994, 0, 1), td: 289, tu: 272, un: 281, tv: 200 },
    { date: new Date(1995, 0, 1), td: 294, tu: 278, un: 288, tv: 180 },
    { date: new Date(1996, 0, 1), td: 297, tu: 284, un: 293, tv: 196 },
    { date: new Date(1997, 0, 1), td: 303, tu: 292, un: 301, tv: 120 },
    { date: new Date(1998, 0, 1), td: 313, tu: 300, un: 311, tv: 155 },
    { date: new Date(1999, 0, 1), td: 322, tu: 309, un: 320, tv: 218 },
    { date: new Date(2000, 0, 1), td: 334, tu: 319, un: 333, tv: 222 },
    { date: new Date(2001, 0, 1), td: 339, tu: 326, un: 342, tv: 201 },
    { date: new Date(2002, 0, 1), td: 341, tu: 332, un: 345, tv: 186 },
    { date: new Date(2003, 0, 1), td: 342, tu: 342, un: 347, tv: 192 },
    { date: new Date(2004, 0, 1), td: 350, tu: 348, un: 355, tv: 212 },
    { date: new Date(2005, 0, 1), td: 354, tu: 356, un: 362, tv: 201 },
    { date: new Date(2006, 0, 1), td: 361, tu: 362, un: 380, tv: 190 },
    { date: new Date(2007, 0, 1), td: 368, tu: 368, un: 397, tv: 187 },
    { date: new Date(2008, 0, 1), td: 367, tu: 362, un: 407, tv: 176 },
    { date: new Date(2009, 0, 1), td: 355, tu: 344, un: 389, tv: 253 },
    { date: new Date(2010, 0, 1), td: 360, tu: 347, un: 411, tv: 215 },
    { date: new Date(2011, 0, 1), td: 366, tu: 349, un: 431, tv: 253 },
    { date: new Date(2012, 0, 1), td: 365, tu: 351, un: 433, tv: 208 },
    { date: new Date(2013, 0, 1), td: 366, tu: 356, un: 434, tv: 202 },
    { date: new Date(2014, 0, 1), td: 365, tu: 364, un: 439, tv: 197 },
    { date: new Date(2015, 0, 1), td: 368, tu: 369, un: 442, tv: 186 },
    { date: new Date(2016, 0, 1), td: 371, tu: 373, un: 446, tv: 251 },
    { date: new Date(2017, 0, 1), td: 378, tu: 377, un: 456, tv: 194 },
    { date: new Date(2018, 0, 1), td: 315, tu: 360, un: 461, tv: 183},
    { date: new Date(2019, 0, 1), td: 385, tu: 346, un: 443, tv: 176 },
    { date: new Date(2020, 0, 1), td: 325, tu: 320, un: 423, tv: 202 },
    { date: new Date(2021, 0, 1), td: 355, tu: 356, un: 456, tv: 213 },
    { date: new Date(2022, 0, 1), td: 385, tu: 318, un: 467, tv: 223 },
    { date: new Date(2023, 0, 1), td: 368, tu: 323, un: 443, tv: 225 },
    { date: new Date(2024, 0, 1), td: 385, tu: 376, un: 422, tv: 239 },
];