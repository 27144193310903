import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import equals from 'validator/lib/equals';

const UserProfilePageComponent = ({ updateUserApiRequest, fetchUser, userInfoFromRedux, setReduxUserState, reduxDispatch, localStorage, sessionStorage }) => {
  const [validated, setValidated] = useState(false);
  const [updateUserResponseState, setUpdateUserResponseState] = useState({ success: "", error: "" });
  const [passwordsMatchState, setPasswordsMatchState] = useState(true);
  const [user, setUser] = useState({})
  const userInfo = userInfoFromRedux;

  const [name, setName] = useState("");                      
  const [lastName, setLastName] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [password, setPassword] = useState("");                      
  const [repassword, setRepassword] = useState("");                      

  const [phoneNumber, setPhoneNumber] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [country, setCountry] = useState("");                      
  const [zipCode, setZipCode] = useState("");                      
  const [city, setCity] = useState("");                      
  const [state, setState] = useState("");                      
  const [isAdmin, setIsAdmin] = useState(false);                       

  const [role, setRole] = useState("");                      
  const [fieldNameError, setFieldNameError] = useState(false);
  const [fieldLastnameError, setFieldLastnameError] = useState(false);
  const [fieldEmailError, setEmailFieldError] = useState(false);
  const [fieldPasswordError, setFieldPasswordError] = useState(false);
  const [fieldRePasswordError, setFieldRePasswordError] = useState(false);

  useEffect(() => {
    console.log(`userInfo =`, userInfo);  
    fetchUser(userInfo._id)
      //.then((data) => setUser(data))
      .then(data => {
        console.log(`data =`, data);  
        setUser(data);
        setName(data?.name);
        setLastName(data?.lastName);
        setEmail(data?.email);
        setPhoneNumber(data?.phoneNumber);
        setAddress(data?.address);
        setCountry(data?.country);
        setZipCode(data?.zipCode);
        setCity(data?.city);
        setState(data?.state);
        setPassword(data?.password);
        setRepassword(data?.password);
        setIsAdmin(data?.isAdmin);
        console.log(`user  =`, user);  
        console.log(`user.name  =`, user.name);  
        console.log(`name  =`, name);  
        console.log(`lastName  =`, lastName);  
        console.log(`email  =`, email);  
        console.log(`phoneNumber  =`, phoneNumber);  
        console.log(`address  =`, address);  
        console.log(`country  =`, country);  
        console.log(`zipCode  =`, zipCode);  
        console.log(`city  =`, city);  
        console.log(`state  =`, state);  
        console.log(`password  =`, password);  
        console.log(`isAdmin  =`, isAdmin);    
      })
      .catch((er) => console.log(er));
  }, [setUser._id])

  const onChange = () => {
    const password = document.querySelector("input[name=password]");
    const confirmPassword = document.querySelector("input[name=confirmPassword]");
    if (confirmPassword.value === password.value) {
      setPasswordsMatchState(true);
    } else {
      setPasswordsMatchState(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;

    /*
    const name = form.name.value;
    const lastName = form.lastName.value;
    const phoneNumber = form.phoneNumber.value;
    const address = form.address.value;
    const country = form.country.value;
    const zipCode = form.zipCode.value;
    const city = form.city.value;
    const state = form.state.value;
    const password = form.password.value;

    if (event.currentTarget.checkValidity() === true && form.password.value === form.confirmPassword.value) {
    */

    //console.log(`password  =`, password);  
    //console.log(`isAdmin  =`, isAdmin);    

    if (event.currentTarget.checkValidity() === true && password === repassword) {

      //console.log(`salvo modifiche all'utente`);    

        updateUserApiRequest(name, lastName, phoneNumber, role, isAdmin , address, city, country, phoneNumber, state, password).then(data => {
            setUpdateUserResponseState({ success: data.success, error: "" });
            reduxDispatch(setReduxUserState({ doNotLogout: userInfo.doNotLogout, ...data.userUpdated }));
            if (userInfo.doNotLogout) localStorage.setItem("userInfo", JSON.stringify({ doNotLogout: true, ...data.userUpdated }));
            else sessionStorage.setItem("userInfo", JSON.stringify({ doNotLogout: false, ...data.userUpdated }));
        })
        .catch((er) => setUpdateUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data }))

        setValidated(true);
        //window.location.assign('/')
    }

  };

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();
  
    window.location.assign('/')
  }

  return (
    <Container>

      <Row className="justify-content-center mt-5">

        <Col md={11}>


          <h1>Modifica il tuo Profilo</h1>
        
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
        
          <Box
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >

            <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name} required
              style={{
                width: "400px",
              }}
              error={fieldNameError}
              onChange={(event) => {
                setName(event.target.value);
                if (isLength(event.target.value,{ min: 3, max:15})) {
                  setFieldNameError(false);
                } else {
                  setFieldNameError(true);
                }   
              }}
            />

            <TextField id="id-lastName" label="Cognome" variant="outlined" value={lastName} defaultValue={lastName} required 
                style={{
                  width: "400px",
                }}
                error={fieldLastnameError}
                onChange={(event) => {
                  setLastName(event.target.value);
                  if (isLength(event.target.value,{ min: 3, max:15})) {
                    setFieldLastnameError(false);
                  } else {
                    setFieldLastnameError(true);
                  }   
                }}
            />

            <TextField id="id-email" label="Email" variant="outlined" value={email} defaultValue={email} required
              style={{
                width: "400px",
              }}
              disabled
              helperText="se desideri modificare l'email devi creare nuovo account"
              error={fieldEmailError}
              onChange={(event) => {
                setEmail(event.target.value);
                if (isEmail(event.target.value)) {
                  setEmailFieldError(false);
                } else {
                  setEmailFieldError(true);
                }   
              }}             
            />

              <TextField id="id-phoneNumber" label="Telefono" variant="outlined" value={phoneNumber} defaultValue={phoneNumber} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
              />

              <TextField id="id-address" label="Indirizzo" variant="outlined" value={address} defaultValue={address} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />
              <TextField id="id-city" label="Città" variant="outlined" value={city} defaultValue={city} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
              <TextField id="id-country" label="Provincia" variant="outlined" value={country} defaultValue={country} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCountry(event.target.value);
                }}
              />
              <TextField id="id-zipCode" label="Cap" variant="outlined" value={zipCode} defaultValue={zipCode} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setZipCode(event.target.value);
                }}
              />

            <TextField id="id-password" label="Password" variant="outlined" required value={password} defaultValue={password} type="password"
                style={{
                  width: "200px",
                }}
                error={fieldPasswordError}
                onChange={(event) => {
                  setPassword(event.target.value);
                  if (isStrongPassword(event.target.value)) {
                    setFieldPasswordError(false);
                  } else {
                    setFieldPasswordError(true);
                  }   
                }}
              />

              <TextField id="id-repassword" label="Ripeti password" variant="outlined" required value={repassword} defaultValue={repassword} type="password"
                style={{
                  width: "200px",
                }}
                error={fieldRePasswordError}
                onChange={(event) => {
                  setRepassword(event.target.value);
                  if (isStrongPassword(event.target.value)) {
                    setFieldRePasswordError(false);
                  } else {
                    setFieldRePasswordError(true);
                  }                 
                }}
              />

          </Box>


        {/* 
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={user.name}
                name="name"
              />
              <Form.Control.Feedback type="invalid">
                inserisci il nome
              </Form.Control.Feedback>
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Cognome</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={user.lastName}
                name="lastName"
              />
              <Form.Control.Feedback type="invalid">
                inserisci il cognome
              </Form.Control.Feedback>
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                disabled
                value={user.email + "   se desideri modificare l'email, rimuovi l'account e creane uno nuovo con un nuovo indirizzo email"}
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci il tuo numero di telefono"
                defaultValue={user.phoneNumber}
                name="phoneNumber"
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Indirizzo</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci via e numero"
                defaultValue={user.address}
                name="address"
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label>Provincia</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci la Provincia"
                defaultValue={user.country}
                name="country"
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicZip">
              <Form.Label>Cap</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci il CAP"
                defaultValue={user.zipCode}
                name="zipCode"
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicCity">
              <Form.Label>Città</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci la Città"
                defaultValue={user.city}
                name="city"
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicState">
              <Form.Label>Nazione</Form.Label>
              <Form.Control
                type="text"
                placeholder="inserisci la Nazione"
                defaultValue={user.state} 
                name="state"
              />
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                required
                type="password"
                placeholder="Password"
                minLength={6}
                onChange={onChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                inserisci una password valida
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                la password deve contenere almeno 6 caratteri
              </Form.Text>
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
              <Form.Label>Ripeti Password</Form.Label>
              <Form.Control
                name="confirmPassword"
                required
                type="password"
                placeholder="repeti la Password"
                minLength={6}
                onChange={onChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                le password devono corrispondere
              </Form.Control.Feedback>
            </Form.Group>
        */}

          <Row className="justify-content-center mt-5">
            <Col md={2}>

              <Button 
                type="button"
                className="btn btn-warning"
                onClick={(e) => {gotoIndietro(e); }}
                style={{
                  width: "90px",
                }}
              >
                Indietro
              </Button>             
            </Col>

            <Col md={2}>

              <Button variant="primary" type="submit"
                style={{
                  width: "90px",
                }}
              >
                Modifica
              </Button>
            
              <Alert show={updateUserResponseState && updateUserResponseState.error !== ""} variant="danger">
                Qualcosa è andato storto
              </Alert>
              <Alert show={updateUserResponseState && updateUserResponseState.success === "user updated"} variant="info">
                Utente modificato
              </Alert>
            </Col>
          </Row>

          <Row className="mt-5 justify-content-md-center"></Row>

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default UserProfilePageComponent;
