const strCurrency = (number)  => {
    return new Intl.NumberFormat(
            'it-IT',
            {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}
          )
        .format(number);
  };

const strEuroCurrency = (number)  => {
    const str=strCurrency(number);
    return str.substring(str.length-1)+' '+str.substring(0,str.length-1)
  }

module.exports = { strCurrency, strEuroCurrency }