export const desktopOS = [
    {
      label: 'Taglio donna',
      value: 35.00,
    },
    {
      label: 'Taglio uomo',
      value: 15.00,
    },
    {
      label: 'Unghie',
      value: 28.00,
    },
    {
      label: 'Trattamenti viso',
      value: 22.00,
    },
  ];
  
  export const mobileOS = [
    {
      label: 'Android',
      value: 70.48,
    },
    {
      label: 'iOS',
      value: 28.8,
    },
    {
      label: 'Other',
      value: 0.71,
    },
  ];
  
    export const platforms = [
    {
        label: 'Taglio uomo',
        value: 40.00,
    },
    {
        label: 'Taglio Donna',
        value: 25.00,
    },
    {
        label: 'Unghie',
        value: 15.00,
        },
        {
        label: 'Trattamenti viso',
        value: 20.00,
        },
    ];
  
    export const platformsResource1 = [
    {
        label: 'Taglio uomo',
        value: 40.00,
    },
    {
        label: 'Taglio Donna',
        value: 25.00,
    },
    {
        label: 'Unghie',
        value: 15.00,
        },
        {
        label: 'Trattamenti viso',
        value: 20.00,
        },
    ];
    
    export const platformsResource2 = [
        {
            label: 'Taglio uomo',
            value: 20.00,
        },
        {
            label: 'Taglio Donna',
            value: 20.00,
        },
        {
            label: 'Unghie',
            value: 10.00,
            },
            {
            label: 'Trattamenti viso',
            value: 40.00,
            },
    ];

    export const platformsResource3 = [
        {
            label: 'Taglio uomo',
            value: 35.00,
        },
        {
            label: 'Taglio Donna',
            value: 15.00,
        },
        {
            label: 'Unghie',
            value: 20.00,
            },
            {
            label: 'Trattamenti viso',
            value: 30.00,
            },
        ];

    export const platformsResource4 = [
        {
            label: 'Taglio uomo',
            value: 25.00,
        },
        {
            label: 'Taglio Donna',
            value: 25.00,
        },
        {
            label: 'Unghie',
            value: 55.00,
            },
            {
            label: 'Trattamenti viso',
            value: 25.00,
            },
        ];

    export const platformsResource5 = [
        {
            label: 'Taglio uomo',
            value: 10.00,
        },
        {
            label: 'Taglio Donna',
            value: 35.00,
        },
        {
            label: 'Unghie',
            value: 25.00,
            },
            {
            label: 'Trattamenti viso',
            value: 40.00,
            },
        ];

    export const platformsResource6 = [
        {
            label: 'Taglio uomo',
            value: 30.00,
        },
        {
            label: 'Taglio Donna',
            value: 30.00,
        },
        {
            label: 'Unghie',
            value: 30.00,
            },
            {
            label: 'Trattamenti viso',
            value: 10.00,
            },
        ];
                    
  const normalize = (v: number, v2: number) => Number.parseFloat(((v * v2) / 100).toFixed(2));
  
  export const mobileAndDesktopOS = [
    ...mobileOS.map((v) => ({
      ...v,
      label: v.label === 'Other' ? 'Other (Mobile)' : v.label,
      value: normalize(v.value, platforms[0].value),
    })),
    ...desktopOS.map((v) => ({
      ...v,
      label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
      value: normalize(v.value, platforms[1].value),
    })),
  ];
  
  export const valueFormatter = (item: { value: number }) => `${item.value}%`;