import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import * as React from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import equals from 'validator/lib/equals';

const config = require("../../constants/constants.js")
const enableCreateAccount = config.ENABLE_CREATE_ACCOUNT;

const RegisterPageComponent = ({
  registerUserApiRequest,
  reduxDispatch,
  setReduxUserState,
}) => {

  const [validated, setValidated] = useState(false);
  const [registerUserResponseState, setRegisterUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });
  const [passwordsMatchState, setPasswordsMatchState] = useState(true);
  
  const onChange = () => {
    const password = document.querySelector("input[name=password]");
    const confirmPassword = document.querySelector("input[name=confirmPassword]");
    if (confirmPassword.value === password.value) {
      setPasswordsMatchState(true);
    } else {
      setPasswordsMatchState(false);
    }
  };

  const handleSubmit = (event) => {
  
    event.preventDefault();
    event.stopPropagation();
  
    console.log('[handleSubmit] isEmail(email)=',isEmail(email));
    console.log('[handleSubmit] password =',password);
    console.log('[handleSubmit] repassword =',repassword);
    console.log('[handleSubmit] password===repassword =',password===repassword);
    console.log('[handleSubmit] isEmail(email)=',isEmail(email));


    const form = event.currentTarget.elements;  
    //const email = form.email.value;
    //const name = form.name.value;
    //const lastName = form.lastName.value;
    //const password = form.password.value;

    if (
      event.currentTarget.checkValidity() === true &&
      name &&
      lastName &&
      email &&
      password &&
      repassword &&
      (password===repassword) 
      ) {

      console.log('[handleSubmit] creo nuovo utente');

      setRegisterUserResponseState({ loading: true });
      registerUserApiRequest(name, lastName, email, password)
        .then((data) => {
          setRegisterUserResponseState({
            success: data.success,
            loading: false,
          });
          reduxDispatch(setReduxUserState(data.userCreated));
          
        })
        .catch((er) =>
          setRegisterUserResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          })
        );
    }

    setValidated(true);
  };

  const [name, setName] = useState("");                      
  const [lastName, setLastName] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [password, setPassword] = useState("");                      
  const [repassword, setRepassword] = useState("");                      
  const [role, setRole] = useState("");                      
 
  const [fieldNameError, setFieldNameError] = useState(false);
  const [fieldLastnameError, setFieldLastnameError] = useState(false);
  const [fieldEmailError, setFieldEmailError] = useState(false);
  const [fieldPasswordError, setFieldPasswordError] = useState(false);
  const [fieldRePasswordError, setFieldRePasswordError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('') 
  
  const [page, setPage] = useState({});  
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [customizedName, setCustomizedName] = useState("");     
  const [customizedStreet, setCustomizedStreet] = useState("");     
  const [customizedZip, setCustomizedZip] = useState("");     
  const [customizedCity, setCustomizedCity] = useState("");     
  const [customizedEmail, setCustomizedEmail] = useState("");     
  const [tabAttivo, setTabAttivo] = React.useState('home');
  const [pathImage1, setPathImage1] = useState("");     
  const [pathImage2, setPathImage2] = useState("");     
  const [pathImage3, setPathImage3] = useState("");     
  const [titleImage1, setTitleImage1] = useState("");     
  const [subTitleImage1, setSubTitleImage1] = useState("");     
  const [titleImage2, setTitleImage2] = useState("");     
  const [subTitleImage2, setSubTitleImage2] = useState("");     
  const [titleImage3, setTitleImage3] = useState("");     
  const [subTitleImage3, setSubTitleImage3] = useState("");     

  const getPageByMenuValueApiRequest = async (menuValue) => {
    console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
    const url = `/api/pages/menuValue/`+menuValue;
    const { data } = await axios.get(url);
    console.log(`data =`, data);  
    return data
  }

  useEffect(() => {
    getPageByMenuValueApiRequest('ACCOUNT').then((data) => { 
      console.log(`data 1 =`, data);  
      setPage(data);
      setTitle(data?.title.text);
      setSubTitle(data?.subTitle.text);
      setDescription(data?.description.text);
      setTabAttivo('home');
      setId(data?._id);
      setPathImage1(data?.images[0].path);
      setPathImage2(data?.images[1].path);
      setPathImage3(data?.images[2].path);
      setCustomizedName(page?.customizedContent.name.text);
      setCustomizedStreet(page?.customizedContent.street.text);
      setCustomizedZip(page?.customizedContent.zip.text);
      setCustomizedCity(page?.customizedContent.city.text);
      setCustomizedEmail(page?.customizedContent.email.text);
      setTitleImage1(data?.imagesTitle[0].text);
      setSubTitleImage1(data?.imagesSubTitle[0].text);
      setTitleImage2(data?.imagesTitle[1].text);
      setSubTitleImage2(data?.imagesSubTitle[1].text);
      setTitleImage3(data?.imagesTitle[2].text);
      setSubTitleImage3(data?.imagesSubTitle[2].text);
      console.log(`id =`, id);  
      console.log(`page?.title.text =`, page?.title.text);  
      console.log(`page?.description.text =`, page?.description.text);  
      console.log(`page?.customizedContent =`, page?.customizedContent);  
      console.log(`page?.customizedContent.name.text =`, page?.customizedContent.zip.text);  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [tabAttivo,id])

  return (
    <Container className="px-0">
    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >
      <Col
        xs={{ order: 2 }}
        md={{ size: 4, order: 1 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage1}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage1}</h3> 
              <p>{subTitleImage1}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage2}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage2}</h3> 
              <p>{subTitleImage2}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage3}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage3}</h3> 
              <p>{subTitleImage3}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

      <Col
        xs={{ order: 1 }}
        md={{ size: 7, offset: 1 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

          <h1>Crea account</h1>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <Box
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >

            <TextField id="id-name" label="Nome" variant="outlined" required
              style={{
                width: "400px",
              }}
              error={fieldNameError}
              onChange={(event) => {
                setName(event.target.value);
                if (isLength(event.target.value,{ min: 3, max:15})) {
                  setFieldNameError(false);
                } else {
                  setFieldNameError(true);
                }   
              }}
            />

            <TextField id="id-lastName" label="Cognome" variant="outlined" required 
                style={{
                  width: "400px",
                }}
                error={fieldLastnameError}
                onChange={(event) => {
                  setLastName(event.target.value);
                  if (isLength(event.target.value,{ min: 3, max:15})) {
                    setFieldLastnameError(false);
                  } else {
                    setFieldLastnameError(true);
                  }   
                }}
            />

            <TextField id="id-email" label="Email" variant="outlined" required
              style={{
                width: "400px",
              }}
              error={fieldEmailError}
              onChange={(event) => {
                setEmail(event.target.value);
                if (isEmail(event.target.value)) {
                  setFieldEmailError(false);
                } else {
                  setFieldEmailError(true);
                }   
              }}             
            />

            <TextField id="id-password" label="Password" variant="outlined" required type="password"
                style={{
                  width: "400px",
                }}
                error={fieldPasswordError}
                onChange={(event) => {
                  setPassword(event.target.value);
                  if (isStrongPassword(event.target.value)) {
                    setFieldPasswordError(false);
                  } else {
                    setFieldPasswordError(true);
                  }   
                }}
              />

              <TextField id="id-repassword" label="Ripeti password" variant="outlined" required type="password"
                style={{
                  width: "400px",
                }}
                error={fieldRePasswordError}
                onChange={(event) => {
                  setRepassword(event.target.value);
                  if (isStrongPassword(event.target.value)) {
                    setFieldRePasswordError(false);
                  } else {
                    setFieldRePasswordError(true);
                  }   
                }}
              />

          </Box>


        {/* 
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="inserisci il tuo nome"
                name="name"
              />
              <Form.Control.Feedback type="invalid">
                Prego inserisci il tuo nome
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Cognome</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="inserisci il tuo cognome"
                name="lastName"
              />
              <Form.Control.Feedback type="invalid">
                Prego inserisci il tuo cognome
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                required
                type="email"
                placeholder="inserisci email"
              />
              <Form.Control.Feedback type="invalid">
                inserisci un indirizzo email valido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                required
                type="password"
                placeholder="Password"
                minLength={6}
                onChange={onChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                Prego inserire una password valida
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                la Password deve avere più 5 caratteri
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
              <Form.Label>Ripeti Password</Form.Label>
              <Form.Control
                name="confirmPassword"
                required
                type="password"
                placeholder="ripeti la Password"
                minLength={6}
                onChange={onChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                le due password devono corrispondere
              </Form.Control.Feedback>
            </Form.Group>
        */}

            <Row className="pb-2">
              <Col>
                Hai già un account?
                <Link to={"/login"}> Login </Link>
              </Col>
            </Row>

            <Button type="submit" disabled={!enableCreateAccount}>
              {registerUserResponseState &&
              registerUserResponseState.loading === true ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}              
              Crea nuovo account
            </Button>
            <Alert show={registerUserResponseState && registerUserResponseState.error === "user exists"} variant="danger">
              Già esiste un Utente con questa email!
            </Alert>
            <Alert show={registerUserResponseState && registerUserResponseState.success === "User created"} variant="info">
              Utente creato
            </Alert>
          </Form>

      </Col>
    </Row>
    
    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>

  );
};

export default RegisterPageComponent;
