import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { Container, Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'; 

// data-picker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { itIT } from "@mui/x-date-pickers/locales";
import 'dayjs/locale/it.js';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

// table
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import Grid from "@mui/material/Grid2";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardHeader, Avatar, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  FavoriteBorderRounded,
  ShareOutlined,
  MoreVert
} from "@mui/icons-material";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModalAppuntamento from "./ModalAppuntamento.js";

import dayjs from 'dayjs'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

// costanti generali
const altezza_cella_appuntamento_px=60;                 // altezza_cella_appuntamento_px => height:90  
const altezza_cella_appuntamento1_px=60;                // altezza_cella_appuntamento1_px => height:100  
const colorBackground_cella_senzaAppuntamento='#fCfcfc';

const resourcesInit = require("../../constants/resourcesInit.js")
const appointmentsInit = require("../../constants/appointmentsInit.js")

const agendaOraAppuntamenti = [
  '7.00',
  '7.15',
  '7.30',
  '7.45',
  '8.00',
  '8.15',
  '8.30',
  '8.45',
  '9.00',
  '9.15',
  '9.30',
  '9.45',
  '10.00',
  '10.15',
  '10.30',
  '10.45',
  '11.00',
  '11.15',
  '11.30',
  '11.45',
  '12.00',
  '12.15',
  '12.30',
  '12.45',
  '13.00',
  '13.15',
  '13.30',
  '13.45',
  '14.00',
  '14.15',
  '14.30',
  '14.45',
  '15.00',
  '15.15',
  '15.30',
  '15.45',
  '16.00',
  '16.15',
  '16.30',
  '16.45',
  '17.00',
  '17.15',
  '17.30',
  '17.45',
  '18.00',
  '18.15',
  '18.30',
  '18.45',
  '19.00',
  '19.15',
  '19.30',
  '19.45',
  '20.00',
  '20.15',
  '20.30',
  '20.45',
  '21.00',
  '21.15',
  '21.30',
  '21.45',
  '22.00',
];

/*
const agendaOraAppuntamenti = [
  '7.00',
  '7.15',
  '7.30',
  '7.45',
]
*/

const ProdottiPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {

  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const config = require("../../constants/constants.js")

  const [isOpenTabAppuntamento, setIsOpenTabAppuntamento] = useState(false);

{/* fnz

  const [modalAppuntamentoGiorno, setModalAppuntamentoGiorno] = useState("");     // contiene il giorno degli appuntamenti

  //const [dataAppuntamenti, setDataAppuntamenti] = useState(dayjs(new Date()));
  const [dataAppuntamenti, setDataAppuntamenti] = useState(dayjs("2024-12-11"));    // è un oggetto

*/}

  // giorno degli Appuntamenti in Edit
  const [modalAppuntamentoGiorno, setModalAppuntamentoGiorno] = useState(dayjs(new Date()).format('YYYY-MM-DD'));  // stringa
  const [dataAppuntamentiMillis, setDataAppuntamentiMillis] = useState(dayjs(new Date()));                                     // oggetto
  
  const [modalAppuntamentoOra, setModalAppuntamentoOra] = useState("");
  const [modalAppuntamentoRiga, setModalAppuntamentoRiga] = useState(0);
  const [modalAppuntamentoColonna, setModalAppuntamentoColonna] = useState(0);

  const [modalAppuntamentoTitoloRisorsa, setModalAppuntamentoTitoloRisorsa] = useState("");
  const [modalAppuntamentoCliente, setModalAppuntamentoCliente] = useState("");
  const [modalAppuntamentoCategoria, setModalAppuntamentoCategoria] = useState("");
  const [modalAppuntamentoProdotto, setModalAppuntamentoProdotto] = useState("");
  const [modalAppuntamentoSito, setModalAppuntamentoSito] = useState("");
  const [modalAppuntamentoDurata, setModalAppuntamentoDurata] = useState(0);
  const [modalAppuntamentoColore, setModalAppuntamentoColore] = useState("");
  const [modalAppuntamentoOwner, setModalAppuntamentoOwner] = useState("");


  const [agenda, setAgenda] = useState({});
  const [resources, setResources] = useState([]);

  const openModal = () => {
    console.log(`opening modal, currently isOpenTabAppuntamento is == ${isOpenTabAppuntamento}`);
    setIsOpenTabAppuntamento(true);
  };

  const closeModalAppuntamento = () => {
    console.log(`closing modal, currently isOpenTabAppuntamento is == ${isOpenTabAppuntamento}`);
    setIsOpenTabAppuntamento(false);
  };

  const modalActionAppuntamento = () => {
    console.log("perform custom action with modal inputs...");
    closeModalAppuntamento();
  };

  const handleMouseDown = e => {
    e.preventDefault();
    console.log("background click");
  };
  
  const getResource = async () => {
    const url = `/api/resources`;
    const { data } = await axios.get(url);
    return data
  }

  const getAppointmentDayMillis = async (dataMillis) => {
    console.log("leggo agendaDay di ",dayjs(dataMillis.$d).format('YYYY-MM-DD'));
    const url = `/api/agendas/day/`+dayjs(dataMillis.$d).format('YYYY-MM-DD');
    const { data } = await axios.get(url);
    return data
  }

  // ---------------------  useEffect INI
  
  useEffect(() => {
    getResource().then((res) => setResources(res))
    .catch((er) => {
      console.log(er);
    });  
  }, [])
 
  useEffect(() => {
    getAppointmentDayMillis(dataAppuntamentiMillis).then((data) => 
      {
        console.log(`[useEffect] data.resource0006appointments[0].appointments = `,data.resource0006appointments[0].appointments);
        setAgenda(data)
        console.log(`[useEffect] agenda = `,agenda);
      })  
      .catch((er) => {
      console.log(er);
    });  
  }, [] )

useEffect(() => {
    setModalAppuntamentoGiorno("2024-12-11");
    console.log(`[useEffect] modalAppuntamentoGiorno = `,modalAppuntamentoGiorno);
    console.log(`[useEffect] dataAppuntamentiMillis = `,dataAppuntamentiMillis);
    console.log(`[useEffect] dayjs(new Date()) = `,dayjs(new Date()));
    console.log(`[useEffect] dayjs(new Date()) = `,dayjs(new Date()).format('YYYY-MM-DD'));
  //dayjs(new Date()).format('YYYY-MM-DD')
  //onClick={(e) => {popupConfermaCreaAgendaDay(e, dayjs(dataAppuntamentiMillis.$d).format('YYYY-MM-DD')) }}



  }, [])
  
  // ---------------------  useEffect FIN

  const ViewCardCellaRisorse = ({ risorsa }) => {
    return (
      <Card 
        style={
          { backgroundColor: '#CCE5FF', height:70, width:170, border: "1px solid lightblue" }
        } 
        sx={
          { minWidth: 200 }
        }
        >
        <CardContent>
          <Typography variant="h6" component="div">
            {risorsa}
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: 14, mb: 0.1 }}>
             
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const ViewCardCellaIntersezione = ({ dataGiorno }) => {
    return (
      <Card style={
            { backgroundColor: '#CCE5FF', height:70, width:100, border: "1px solid lightblue"}
          } 
        sx={
          { minWidth: 80 }
        } 
      >
        <CardContent>
          <Typography sx={{ color: 'text.secondary', fontSize: 6, mb: 0.1 }}>
            &nbsp;          
          </Typography>
          <Typography sx={{ color: 'text.secondary', fontSize: 12, mb: 0.1 }}>
            {dataGiorno}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const ViewCardCellaOrario = ({ hour, row }) => {
    return (
      <Card style={
          { backgroundColor: '#CCE5FF', height:altezza_cella_appuntamento1_px, width:100, border: "1px solid lightblue"}
        } 
        sx={
          { minWidth: 80 }
        } 
      >
        <CardContent>
          <Typography variant="h6" component="div" sx={{ color: 'text.secondary', fontSize: 18, mb: 1, textAlign: "center" }}>
          {hour}
          </Typography>
        </CardContent>
      </Card>
    );
  };


  const ViewCardCellaAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {

    let colorBackground=colore;     
    let durataVisualizzata=durata+" min";
    if (cliente.trim()==="") {
      colorBackground=colorBackground_cella_senzaAppuntamento;
      durataVisualizzata="";
    }
    categoria=categoria.substring(0,16);
    servizio=servizio.substring(0, 8);

    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height: altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderTop: "3px outset lightblue", borderBottom: "3px outset lightblue",
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >

        <CardActions style={{ border: "none", padding:4 }}>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >

            <TableContainer              
              style={
                { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0, border: "none"}
              } 
            >
              <Table sx={{ minWidth: 160 }} size="small" style={{ border: "none" }}>   
                <TableBody>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 14, my:-3, mt:0, mb: 0 }}>              
                        {cliente} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(140 140 140)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {durataVisualizzata} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {categoria} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {servizio} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(180 152 58)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {sito} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                      </Typography>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const ViewCardCellaInizioAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {
    
    let colorBackground=colore;     
    let durataVisualizzata=durata+" min";
    if (cliente.trim()==="") {
      colorBackground=colorBackground_cella_senzaAppuntamento;
      durataVisualizzata="";
    }
    categoria=categoria.substring(0,16);
    servizio=servizio.substring(0, 8);

    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height:altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderTop: "3px outset lightblue", 
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >
        <CardActions>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >

            <TableContainer              
              style={
                { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0, border: "none"}
              } 
            >
              <Table sx={{ minWidth: 160 }} size="small" style={{ border: "none" }}>   
                <TableBody>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 14, my:-3, mt:0, mb: 0 }}>              
                        {cliente} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(140 140 140)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {durataVisualizzata} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {categoria} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(44 44 44)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {servizio} 
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell component="th" scope="row" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(180 152 58)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                        {sito} 
                      </Typography>
                    </TableCell>
                    <TableCell align="right" style={{ border: "none", padding:0 }}>
                      <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 12, my:-3, mt:0, mb: 0 }}>              
                      </Typography>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const ViewCardCellaIntermediaAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {

     //let colorBackground="#FCE8B7";     
     let colorBackground=colore;     
     let durataVisualizzata=durata+" min";
     if (cliente.trim()==="") {
       colorBackground=colorBackground_cella_senzaAppuntamento;
       durataVisualizzata="";
     }

    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height:altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >
        <CardActions>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >
            <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 16, my:-3, mt:0, mb: 0 }}>
              
            </Typography>
            </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const ViewCardCellaFineAppuntamento = ({ ora, cliente , categoria , servizio , durata, colore, sito , owner, rigaOra, colonnaRisorsa }) => {
 
      //let colorBackground="#FCE8B7";     
      let colorBackground=colore;     
      let durataVisualizzata=durata+" min";
      if (cliente.trim()==="") {
        colorBackground=colorBackground_cella_senzaAppuntamento;
        durataVisualizzata="";
      }
 
    return (
      <Card 
        onClick={(event) => {
          clickCellaAppuntamento(event, rigaOra, colonnaRisorsa, cliente, categoria, servizio, sito)
        }}
        style={
          { backgroundColor: colorBackground, height:altezza_cella_appuntamento1_px, width:170, padding: 0, margin:0, 
            borderBottom: "3px outset lightblue",
            borderLeft: "3px outset lightblue", borderRight: "3px outset lightblue"
          }
        } 
        sx={
          { minWidth: 200 }          
        }
      >
        <CardActions>
          <CardContent 
            style={
              { backgroundColor: colorBackground, height:altezza_cella_appuntamento_px, width:170, my:0, mt:0 , md:0, pt:0, margin:0, padding:0}
            } 
          >
            <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 16, my:-3, mt:0, mb: 0 }}>
              
            </Typography>
            </CardContent>
        </CardActions> 
      </Card>
    )
  };

  const createAgendaApiRequest = async (formInputs) => {
    console.log(' [createAgendaApiRequest] formInputs=',formInputs);
    const { data } = await axios.post(`/api/agendas`, { ...formInputs });
    return data;
  };

  function popupConfermaCreaAgendaDay (eventKey,giornoAgendaMillis) {

    //eventKey.preventDefault();
    //eventKey.stopPropagation();

    console.log(`verifico se agendaDay è nel DB = `,giornoAgendaMillis);
  
  {/*  
    // verifica se agendaDay già esiste
    // http://localhost:3000/api/agendas/day/2024-12-05

    getAppointmentDayMillis(giornoAgendaMillis).then((data) => 
      {
        console.log(`GET responseAgenda = `,data);
        setAgendaNuovaData(data);
        console.log(`GET agenda = `,agendaNuovaData);
      })  
      .catch((er) => {
        console.log(er);
        }
      );  

    console.log(`agendaNuovaData = `,agendaNuovaData);

    if (agendaNuovaData==='KO')
    {
      console.log(`day da creare = `,giornoAgendaMillis);

    } else
    {
      console.log(`day già esistente = `,giornoAgendaMillis);
    }
  */}

    const choice = window.confirm(
      "Vuoi pianificare appuntamenti per il giorno "+ dayjs(giornoAgendaMillis.$d).format('YYYY-MM-DD')+" ?"
    );
    if (choice) {
      // storicizza nel DB il nuovo giornoAppuntamento
  
      const formInputs = {
        day: dayjs(giornoAgendaMillis.$d).format('YYYY-MM-DD'),
        dayOfYear: 300,
        weekOfYear: 40,
        title: "",
        description: "",
        note: "",
        columnResource: resourcesInit,
        resource0001appointments: appointmentsInit,
        resource0002appointments: appointmentsInit,
        resource0003appointments: appointmentsInit,
        resource0004appointments: appointmentsInit,
        resource0005appointments: appointmentsInit,
        resource0006appointments: appointmentsInit
      };
  
      createAgendaApiRequest(formInputs);

      setModalAppuntamentoGiorno(dayjs(giornoAgendaMillis.$d).format('YYYY-MM-DD'));
      
      //window.location.assign('/admin/customers')
    }
  };
 
  const clickCellaAppuntamento = (event, riga, colonna, cliente, categoria, servizio, sito) => {

    console.log(`[clickCellaAppuntamento] riga =`, riga);  
    console.log(`[clickCellaAppuntamento] colonna  =`, colonna);  
    console.log(`[clickCellaAppuntamento] appointment  =`, agenda.resource0001appointments[riga].appointment);  

    // riga = orario  

    // colonna = risorsa => decide che array
    let rigaAppointment = colonna;     
    setModalAppuntamentoTitoloRisorsa(agenda.columnResource[colonna].resourceTitle);
    if (colonna===0) {
      setModalAppuntamentoCliente(agenda.resource0001appointments[riga].appointment.customer);
      setModalAppuntamentoCategoria(agenda.resource0001appointments[riga].appointment.category);
      setModalAppuntamentoProdotto(agenda.resource0001appointments[riga].appointment.product);
      setModalAppuntamentoSito(agenda.resource0001appointments[riga].appointment.site);
      setModalAppuntamentoDurata(agenda.resource0001appointments[riga].appointment.durationMins);
      setModalAppuntamentoColore(agenda.resource0001appointments[riga].appointment.color);
      setModalAppuntamentoOwner(agenda.resource0001appointments[riga].appointment.owner);
      setModalAppuntamentoOra(agenda.resource0001appointments[riga].hour);
    }
    if (colonna===1) {
      setModalAppuntamentoCliente(agenda.resource0002appointments[riga].appointment.customer);
      setModalAppuntamentoCategoria(agenda.resource0002appointments[riga].appointment.category);
      setModalAppuntamentoProdotto(agenda.resource0002appointments[riga].appointment.product);
      setModalAppuntamentoSito(agenda.resource0002appointments[riga].appointment.site);
      setModalAppuntamentoDurata(agenda.resource0002appointments[riga].appointment.durationMins);
      setModalAppuntamentoColore(agenda.resource0002appointments[riga].appointment.color);
      setModalAppuntamentoOwner(agenda.resource0002appointments[riga].appointment.owner);
      setModalAppuntamentoOra(agenda.resource0002appointments[riga].hour);
    }
    if (colonna===2) {
      setModalAppuntamentoCliente(agenda.resource0003appointments[riga].appointment.customer);
      setModalAppuntamentoCategoria(agenda.resource0003appointments[riga].appointment.category);
      setModalAppuntamentoProdotto(agenda.resource0003appointments[riga].appointment.product);
      setModalAppuntamentoSito(agenda.resource0003appointments[riga].appointment.site);
      setModalAppuntamentoDurata(agenda.resource0003appointments[riga].appointment.durationMins);
      setModalAppuntamentoColore(agenda.resource0003appointments[riga].appointment.color);
      setModalAppuntamentoOwner(agenda.resource0003appointments[riga].appointment.owner);
      setModalAppuntamentoOra(agenda.resource0003appointments[riga].hour);
    }
    if (colonna===3) {
      setModalAppuntamentoCliente(agenda.resource0004appointments[riga].appointment.customer);
      setModalAppuntamentoCategoria(agenda.resource0004appointments[riga].appointment.category);
      setModalAppuntamentoProdotto(agenda.resource0004appointments[riga].appointment.product);
      setModalAppuntamentoSito(agenda.resource0004appointments[riga].appointment.site);
      setModalAppuntamentoDurata(agenda.resource0004appointments[riga].appointment.durationMins);
      setModalAppuntamentoColore(agenda.resource0004appointments[riga].appointment.color);
      setModalAppuntamentoOwner(agenda.resource0004appointments[riga].appointment.owner);
      setModalAppuntamentoOra(agenda.resource0004appointments[riga].hour);
    }
    if (colonna===4) {
      setModalAppuntamentoCliente(agenda.resource0005appointments[riga].appointment.customer);
      setModalAppuntamentoCategoria(agenda.resource0005appointments[riga].appointment.category);
      setModalAppuntamentoProdotto(agenda.resource0005appointments[riga].appointment.product);
      setModalAppuntamentoSito(agenda.resource0005appointments[riga].appointment.site);
      setModalAppuntamentoDurata(agenda.resource0005appointments[riga].appointment.durationMins);
      setModalAppuntamentoColore(agenda.resource0005appointments[riga].appointment.color);
      setModalAppuntamentoOwner(agenda.resource0005appointments[riga].appointment.owner);
      setModalAppuntamentoOra(agenda.resource0005appointments[riga].hour);
    }
    if (colonna===5) {
      setModalAppuntamentoCliente(agenda.resource0006appointments[riga].appointment.customer);
      setModalAppuntamentoCategoria(agenda.resource0006appointments[riga].appointment.category);
      setModalAppuntamentoProdotto(agenda.resource0006appointments[riga].appointment.product);
      setModalAppuntamentoSito(agenda.resource0006appointments[riga].appointment.site);
      setModalAppuntamentoDurata(agenda.resource0006appointments[riga].appointment.durationMins);
      setModalAppuntamentoColore(agenda.resource0006appointments[riga].appointment.color);
      setModalAppuntamentoOwner(agenda.resource0006appointments[riga].appointment.owner);
      setModalAppuntamentoOra(agenda.resource0006appointments[riga].hour);
    }

    setModalAppuntamentoRiga(riga);
    setModalAppuntamentoColonna(colonna);   
    console.log(`[clickCellaAppuntamento] risorsa  =`, modalAppuntamentoTitoloRisorsa);  
    console.log(`[clickCellaAppuntamento] categoria =`, modalAppuntamentoCategoria);  
    console.log(`[clickCellaAppuntamento] servizio  =`, modalAppuntamentoProdotto);  
    console.log(`[clickCellaAppuntamento] sito =`, modalAppuntamentoSito);  
    console.log(`[clickCellaAppuntamento] owner =`, modalAppuntamentoOwner);  

    openModal(); 
  };

  return (
    <Container fluid className="px-0">

      <Row
        g-0
        className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
      >
        
         
          <Form.Group className="mb-3" controlId="validationCustom01">

          <Row className="justify-content-md-center">
              <Col md={3} className="justify-content-md-right">
                <Form.Label><em><b><h3>Appuntamenti</h3></b></em></Form.Label>
              </Col>

              <Col md={4}
                  style={{
                      width: "180px",
                    }}
              >     
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                  <DatePicker 
                    defaultValue={dataAppuntamentiMillis}
                    value={dataAppuntamentiMillis}
                    onChange={setDataAppuntamentiMillis}
                    inputFormat="dd-MM-yyyy"
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Col>

              <Col md={2}>     
                <Button variant="secondary"
                  onClick={(e) => {popupConfermaCreaAgendaDay(e, dataAppuntamentiMillis) }}
                  >
                  Crea
                </Button>
              </Col>
            </Row >

          </Form.Group> 

    
        {isOpenTabAppuntamento && (
          <ModalAppuntamento
            id="modal"
            isOpenTabAppuntamento={isOpenTabAppuntamento}
            closeModal={closeModalAppuntamento}
            modalAction={modalActionAppuntamento}
            handleMouseDown={handleMouseDown}

            giorno={modalAppuntamentoGiorno}
            ora={modalAppuntamentoOra}
            riga={modalAppuntamentoRiga}
            colonna={modalAppuntamentoColonna}
            titoloRisorsa={modalAppuntamentoTitoloRisorsa}
            cliente={modalAppuntamentoCliente}
            categoria={modalAppuntamentoCategoria}
            prodotto={modalAppuntamentoProdotto}
            sito={modalAppuntamentoSito}
            durata={modalAppuntamentoDurata}
            colore={modalAppuntamentoColore}
            owner={modalAppuntamentoOwner}
            giornoAgenda={agenda}
          >
          </ModalAppuntamento>
        )}

    </Row>
        
    <Row
      g-0
      className="pt-2 pt-md-2 w-100 px-4 px-xl-0 position-relative"
    >


      <Grid container className="grid-container">

            <Grid item xs={5}>
              <ViewCardCellaIntersezione dataGiorno={modalAppuntamentoGiorno}  />
            </Grid>

            { agenda.columnResource?.map((res) => {
                                return (
                                  <Grid item xs={5}>
                                    <ViewCardCellaRisorse risorsa={res.resourceTitle} />
                                  </Grid>
                                  );
            })}


        {/* 

              <ViewCardCellaIntersezione dataGiorno="2024-12-05"  />
        modalAppuntamentoGiorno

        <Grid
          item
          xs={6}
          className={"grid-column"}
          style={{ backgroundColor: "pink" }}
        >
        */}

        <Grid
          item
          xs={6}
          className={"grid-column"}
          style={{ backgroundColor: "lightcyan" }}
        >


          { agendaOraAppuntamenti.map((ora, indexOra) => {
            return (
              <Grid container>

                <Grid item xs={5}>
                  <ViewCardCellaOrario hour={ora} row="3"  />
                </Grid>

                { agenda.columnResource?.map((res,indexRisorsa) => {
                    //console.log("ciclo columnResource: indexRisorsa =",indexRisorsa)                
                    //console.log("ciclo columnResource: indexOra =",indexOra)                
                    //console.log("ciclo columnResource: hour 1l =",agenda.resource0001appointments[indexOra].hour)                
                    //console.log("ciclo columnResource: hour 2l =",agenda.resource0001appointments[indexOra].appointment.owner)                
                    
                    // --------------------------------- resource0001
                    //
                    // cella unica x l'appuntamento
                    
                    //console.log("UNICA indexRisorsa =",indexRisorsa)                
                    //console.log("UNICA indexOra =",indexOra)                
                    //console.log("UNICA ora =",agenda.resource0001appointments[indexOra].hour)
                    //console.log("UNICA length =",agenda.resource0001appointments.length-1)    
                    //console.log("UNICA <",10<12)    
                    //console.log("UNICA  ",indexOra==agenda.resource0001appointments.lenght-1)                
                    //console.log("UNICA  ",(indexOra===0 || !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    
                    if ((indexRisorsa===0) 
                    && (agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && ((indexOra<agenda.resource0001appointments.length-1 && !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner))
                        || indexOra===agenda.resource0001appointments.length-1) 
                    && (indexOra===0 || !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    // cella di inizio appuntamento
                    if ((indexRisorsa===0) 
                    && (agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner)
                    && !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner))
                    {
                      //console.log("INIZIO indexRisorsa =",indexRisorsa)                
                      //console.log("INIZIO indexOra =",indexOra)                
                      //console.log("INIZIO  ",agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner)                
                      //console.log("INIZIO  ",!(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner))
                      
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaInizioAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    // cella intermedia appuntamento
                    if ((indexRisorsa===0) 
                    && !(agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner))
                    && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    if ((indexRisorsa===0) && (indexOra=5))
                    {
                      //console.log("FINE indexRisorsa =",indexRisorsa)                
                      //console.log("FINE indexOra =",indexOra)                
                      //console.log("FINE length =",agenda.resource0001appointments.length-1)    
                      //console.log("FINE 1a cond ",!(agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner))                
                      //console.log("FINE 2b cond =",!(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner))    
                      //console.log("FINE 3a cond ",((indexOra>0) && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    }
       
                    // cella fine appuntamento
                    if ((indexRisorsa===0) 
                    && !(agenda.resource0001appointments[indexOra].hour===agenda.resource0001appointments[indexOra].appointment.owner)
                    && !(agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra+1].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0001appointments[indexOra].appointment.owner===agenda.resource0001appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaFineAppuntamento ora={agenda.resource0001appointments[indexOra].hour} cliente={agenda.resource0001appointments[indexOra].appointment.customer} categoria={agenda.resource0001appointments[indexOra].appointment.category} servizio={agenda.resource0001appointments[indexOra].appointment.product} durata={agenda.resource0001appointments[indexOra].appointment.durationMins} colore={agenda.resource0001appointments[indexOra].appointment.color} sito={agenda.resource0001appointments[indexOra].appointment.site} owner={agenda.resource0001appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }

                    // --------------------------------- resource0002
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===1) 
                    && (agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && ((indexOra<agenda.resource0002appointments.length-1 && !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner))
                        || indexOra===agenda.resource0002appointments.length-1) 
                    && (indexOra===0 || !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella di inizio appuntamento
                    if ((indexRisorsa===1) 
                    && (agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner)
                    && !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaInizioAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella intermedia appuntamento
                    if ((indexRisorsa===1) 
                    && !(agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner))
                    && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella fine appuntamento
                    if ((indexRisorsa===1) 
                    && !(agenda.resource0002appointments[indexOra].hour===agenda.resource0002appointments[indexOra].appointment.owner)
                    && !(agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra+1].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0002appointments[indexOra].appointment.owner===agenda.resource0002appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaFineAppuntamento ora={agenda.resource0002appointments[indexOra].hour} cliente={agenda.resource0002appointments[indexOra].appointment.customer} categoria={agenda.resource0002appointments[indexOra].appointment.category} servizio={agenda.resource0002appointments[indexOra].appointment.product} durata={agenda.resource0002appointments[indexOra].appointment.durationMins} colore={agenda.resource0002appointments[indexOra].appointment.color} sito={agenda.resource0002appointments[indexOra].appointment.site} owner={agenda.resource0002appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
        
                    // --------------------------------- resource0003
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===2) 
                    && (agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && ((indexOra<agenda.resource0003appointments.length-1 && !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner))
                        || indexOra===agenda.resource0003appointments.length-1) 
                    && (indexOra===0 || !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella di inizio appuntamento
                    if ((indexRisorsa===2) 
                    && (agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner)
                    && !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaInizioAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella intermedia appuntamento
                    if ((indexRisorsa===2) 
                    && !(agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner))
                    && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
                    // cella fine appuntamento
                    if ((indexRisorsa===2) 
                    && !(agenda.resource0003appointments[indexOra].hour===agenda.resource0003appointments[indexOra].appointment.owner)
                    && !(agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra+1].appointment.owner)
                    && ((indexOra>0) && (agenda.resource0003appointments[indexOra].appointment.owner===agenda.resource0003appointments[indexOra-1].appointment.owner)))
                    {
                      return (
                        <Grid item xs={5}>
                              <ViewCardCellaFineAppuntamento ora={agenda.resource0003appointments[indexOra].hour} cliente={agenda.resource0003appointments[indexOra].appointment.customer} categoria={agenda.resource0003appointments[indexOra].appointment.category} servizio={agenda.resource0003appointments[indexOra].appointment.product} durata={agenda.resource0003appointments[indexOra].appointment.durationMins} colore={agenda.resource0003appointments[indexOra].appointment.color} sito={agenda.resource0003appointments[indexOra].appointment.site} owner={agenda.resource0003appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                        </Grid>
                      );
                    }
    
                    // --------------------------------- resource0004
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===3) 
                      && (agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && ((indexOra<agenda.resource0004appointments.length-1 && !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner))
                          || indexOra===agenda.resource0004appointments.length-1) 
                      && (indexOra===0 || !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella di inizio appuntamento
                      if ((indexRisorsa===3) 
                      && (agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner)
                      && !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaInizioAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella intermedia appuntamento
                      if ((indexRisorsa===3) 
                      && !(agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner))
                      && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella fine appuntamento
                      if ((indexRisorsa===3) 
                      && !(agenda.resource0004appointments[indexOra].hour===agenda.resource0004appointments[indexOra].appointment.owner)
                      && !(agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra+1].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0004appointments[indexOra].appointment.owner===agenda.resource0004appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaFineAppuntamento ora={agenda.resource0004appointments[indexOra].hour} cliente={agenda.resource0004appointments[indexOra].appointment.customer} categoria={agenda.resource0004appointments[indexOra].appointment.category} servizio={agenda.resource0004appointments[indexOra].appointment.product} durata={agenda.resource0004appointments[indexOra].appointment.durationMins} colore={agenda.resource0004appointments[indexOra].appointment.color} sito={agenda.resource0004appointments[indexOra].appointment.site} owner={agenda.resource0004appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
  
                    // --------------------------------- resource0005
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===4) 
                      && (agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && ((indexOra<agenda.resource0005appointments.length-1 && !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner))
                          || indexOra===agenda.resource0005appointments.length-1) 
                      && (indexOra===0 || !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella di inizio appuntamento
                      if ((indexRisorsa===4) 
                      && (agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner)
                      && !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaInizioAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella intermedia appuntamento
                      if ((indexRisorsa===4) 
                      && !(agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner))
                      && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella fine appuntamento
                      if ((indexRisorsa===4) 
                      && !(agenda.resource0005appointments[indexOra].hour===agenda.resource0005appointments[indexOra].appointment.owner)
                      && !(agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra+1].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0005appointments[indexOra].appointment.owner===agenda.resource0005appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaFineAppuntamento ora={agenda.resource0005appointments[indexOra].hour} cliente={agenda.resource0005appointments[indexOra].appointment.customer} categoria={agenda.resource0005appointments[indexOra].appointment.category} servizio={agenda.resource0005appointments[indexOra].appointment.product} durata={agenda.resource0005appointments[indexOra].appointment.durationMins} colore={agenda.resource0005appointments[indexOra].appointment.color} sito={agenda.resource0005appointments[indexOra].appointment.site} owner={agenda.resource0005appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
  
                    // --------------------------------- resource0006
                    //
                    // cella unica x l'appuntamento
                    if ((indexRisorsa===5) 
                      && (agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && ((indexOra<agenda.resource0006appointments.length-1 && !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner))
                          || indexOra===agenda.resource0006appointments.length-1) 
                      && (indexOra===0 || !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella di inizio appuntamento
                      if ((indexRisorsa===5) 
                      && (agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner)
                      && !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaInizioAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella intermedia appuntamento
                      if ((indexRisorsa===5) 
                      && !(agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner))
                      && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaIntermediaAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }
                      // cella fine appuntamento
                      if ((indexRisorsa===5) 
                      && !(agenda.resource0006appointments[indexOra].hour===agenda.resource0006appointments[indexOra].appointment.owner)
                      && !(agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra+1].appointment.owner)
                      && ((indexOra>0) && (agenda.resource0006appointments[indexOra].appointment.owner===agenda.resource0006appointments[indexOra-1].appointment.owner)))
                      {
                        return (
                          <Grid item xs={5}>
                                <ViewCardCellaFineAppuntamento ora={agenda.resource0006appointments[indexOra].hour} cliente={agenda.resource0006appointments[indexOra].appointment.customer} categoria={agenda.resource0006appointments[indexOra].appointment.category} servizio={agenda.resource0006appointments[indexOra].appointment.product} durata={agenda.resource0006appointments[indexOra].appointment.durationMins} colore={agenda.resource0006appointments[indexOra].appointment.color} sito={agenda.resource0006appointments[indexOra].appointment.site} owner={agenda.resource0006appointments[indexOra].appointment.owner} rigaOra={indexOra} colonnaRisorsa={indexRisorsa} />
                          </Grid>
                        );
                      }

                }
                
                )}

              </Grid>              
              )           
          })}

          </Grid>

      </Grid>
    </Row>


    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>     
    </Row>



  </Container>
  );
};

export default ProdottiPageComponent;
