import ConfigsPageComponent from "./components/ConfigsPageComponent";
import axios from "axios";
import socketIOClient from "socket.io-client";

const fetchOrdersForFirstDate = async (abctrl, firstDateToCompare) => {
    const { data } = await axios.get("/api/orders/configs/" + firstDateToCompare, {
    signal: abctrl.signal,
  });
  return data;
}

const fetchOrdersForSecondDate = async (abctrl, secondDateToCompare) => {
  const { data } = await axios.get("/api/orders/configs/" + secondDateToCompare, {
    signal: abctrl.signal,
  });
  return data;
};

const AdminConfigsPage = () => {
  return <ConfigsPageComponent fetchOrdersForFirstDate={fetchOrdersForFirstDate} fetchOrdersForSecondDate={fetchOrdersForSecondDate} socketIOClient={socketIOClient} />;
};

export default AdminConfigsPage;
