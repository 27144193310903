export const durationsMins = [
    {
      "message": '15 minuti',
      "valueMins": 15
    },
    {
      "message": '30 minuti',
      "valueMins": 30
    },
    {
      "message": '45 minuti',
      "valueMins": 45
    },
    {
      "message": '1 ora',
      "valueMins": 60
    },
    {
      "message": '1 ora 15 minuti',
      "valueMins": 75
    },
    {
      "message": '1 ora 30 minuti',
      "valueMins": 90
    },
    {
      "message": '1 ora 55 minuti',
      "valueMins": 105
    },
    {
      "message": '2 ore',
      "valueMins": 120
    },
    {
      "message": '2 ore 15 minuti',
      "valueMins": 135
    },
    {
      "message": '2 ore 30 minuti',
      "valueMins": 150
    },
    {
      "message": '2 ore 45 minuti',
      "valueMins": 165
    },
    {
      "message": '3 ore',
      "valueMins": 180
    },
    {
      "message": '3 ore 15 minuti',
      "valueMins": 195
    },
    {
      "message": '3 ore 30 minuti',
      "valueMins": 210
    },
    {
      "message": '3 ore 45 minuti',
      "valueMins": 225
    },
    {
      "message": '4 ore',
      "valueMins": 240
    }
  ];